<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { revenueChart } from "./data-profile";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
/**
 * Contacts-Profile component
 */
export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    // PageHeader, 
    loaderProcess
  },
  data() {
    return {
      imageSrc: "",
      modifier: "",
      process: false,
      user: [],
      revenueChart: revenueChart,
      modalOk: false,
      title: "Profile",
      email: "",
      name: "",
      surname: "",
      telephone: "",
      username: "",
      imageUpdate: false,
      items: [
        {
          text: "Contacts",
          // href: "/"
        },
        {
          text: "Profile",
          active: true
        }
      ],
      statData: [
        {
          icon: "bx bx-check-circle",
          title: "Completed Projects",
          value: "125"
        },
        {
          icon: "bx bx-hourglass",
          title: "Pending Projects",
          value: "12"
        },
        {
          icon: "bx bx-package",
          title: "Total Revenue",
          value: "$36,524"
        }
      ],
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"))


    this.email = this.user.email
    this.username = this.user.username
    this.name = this.user.name
    this.surname = this.user.surname
    this.telephone = this.user.telephone


    console.log(this.user)

  },
  methods: {
    showMoal() {
      this.modalOk = true
    },
    updateImage() {
      this.$refs.profileImg.click()
      this.imageUpdate = true
    },
    onFileSeelcted(event) {
      this.selectedFile = event.target.files[0]
      console.log(this.selectedFile)

      const reader = new FileReader();
      reader.onload = (event) => {
        this.imageSrc = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    setImage() {
      this.process = true
      this.imageUpdate = false
      Api.putFormData("/authentication/api/auth/update-photo-user/" + this.user.id, {
        file: this.selectedFile,
        username: this.user.username
      })
        .then(function (response) {
          // this.process = false
          localStorage.setItem('user', JSON.stringify(response.data))
          console.log(response.data);
          location.reload()
        }).catch((error) => {
          this.process = false
          console.log(error)
          Erreur.gestionErreur(error.message)
        })
    },
    setInfos() {
      // this.process = true
      this.modalOk = false
      Api.put('/authentication/api/auth/update-user',
        {
          codepays: "+237",
          email: this.email,
          name: this.name,
          surname: this.surname,
          telephone: this.telephone,
          username: this.username
        })
        .then((response) => {
          this.process = false
          console.log(response.data)
          localStorage.setItem('user', JSON.stringify(response.data))
          location.reload()
        }).catch((error) => {
          this.process = false
          console.log(error)
          Erreur.gestionErreur(error.message)
        })
    },
  },
};
</script>

<template>
  <Layout>
  <!-- <PageHeader :title="title" :items="items" /> -->
  <div class="c2play-primary" style="min-height: 200px; padding: 7%;">
    <h2 class="c2play-primary">Profile</h2>
  </div>
  <div class="card" style="width: 86%; margin-left: 7%; position: relative; bottom: 70px; padding: 80px;">
    <div class="row">

      <div class="col-lg-3">
        <div class="" style="position: relative; left: 10%;">

          <div>
            <img v-if="user.image" :src="user.image.url"
              style="height: 80px;width: 80px; border-radius: 50%; position: relative; bottom: 20px; background-color: #fff;" />

              <span v-if="!user.image" class="c2play-bg-secondary avatar-title rounded-circle bg-soft text-primary font-size-24">
                <img src="@/assets/logos/images/profile.png" alt class="img-thumbnail rounded-circle" />
              </span>
            
            <div style=" position: relative; bottom: 20px;">
              <i class="mdi mdi-account-edit-outline update-user user-img" @click="updateImage()"></i>
            </div>
            </div>
            <h3 class="text-truncate">{{ user.username }}</h3>
          </div>

          <div class="pt-4" style="position: relative; right: 10%; font-size: 1.2em;">
            <div class="row">
              <div class="col-6">
                <h5 class="font-size-15">125</h5>
                <p class="text-muted mb-0">Projects</p>
              </div>
              <div class="col-6">
                <h5 class="font-size-15">$1245</h5>
                <p class="text-muted mb-0">Revenue</p>
              </div>
            </div>
          </div>
          <div class=".c2play-border-primary"
            style="height: 130%; width: 0; position: relative; left: 100%; bottom: 72%; border: 1px solid #eef8ea"></div>
        </div>
        <div class="col-lg-9" style="font-size: 1.2em;">
          <div class="row">
            <div class="col-lg-8">
              <h4 class="card-title mb-4" style="font-size: 1.3em;">{{ $t('pages.userProfile.entete.title') }}</h4>
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-1 update-user" @click="showMoal" style="font-size: 1.3em;"><i
                class="mdi mdi-account-edit-outline"></i></div>
          </div>

          <p class="text-muted mb-4"></p>
          <div class="table-responsive ">
            <table class="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">{{ $t('pages.userProfile.entete.th1') }} :</th>
                  <td>{{ user.name }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('pages.userProfile.entete.th2') }} :</th>
                  <td>{{ user.surname }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('pages.userProfile.entete.th3') }} :</th>
                  <td>{{ user.username }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('pages.userProfile.entete.th4') }} :</th>
                  <td>{{ user.telephone }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('pages.userProfile.entete.th5') }} :</th>
                  <td>{{ user.email }}</td>
                </tr>
                <tr>
                  <th scope="row">{{ $t('pages.userProfile.entete.th6') }} :</th>
                  <td>
                    <span v-for="rol in user.roles" :key="rol.id" style="margin-right: 10px;">
                      <span v-if="rol == 'ROLE_ADMIN'" class="bg-success badge">{{ $t('roles.admin') }}</span>
                      <span v-if="rol == 'ROLE_MODERATOR'" class="bg-warning badge">{{ $t('roles.mod') }}</span>
                      <span v-if="rol == 'ROLE_CREATOR'" class="bg-danger badge">{{ $t('roles.create') }}</span>
                      <span v-if="rol == 'ROLE_USER'" class="bg-primary badge">{{ $t('roles.user') }}</span>
                      <span v-if="rol == 'ROLE_EDITOR'" class="bg-info badge">{{ $t('roles.edit') }}</span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table><br>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row" style="font-size: 1.3em;">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">My Projects</h4>
                <div class="table-responsive">
                  <table class="table table-nowrap table-hover mb-0">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Projects</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">Deadline</th>
                        <th scope="col">Budget</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Skote admin UI</td>
                        <td>2 Sep, 2019</td>
                        <td>20 Oct, 2019</td>
                        <td>$506</td>
                      </tr>

                      <tr>
                        <th scope="row">2</th>
                        <td>Skote admin Logo</td>
                        <td>1 Sep, 2019</td>
                        <td>2 Sep, 2019</td>
                        <td>$94</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Redesign - Landing page</td>
                        <td>21 Sep, 2019</td>
                        <td>29 Sep, 2019</td>
                        <td>$156</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>App Landing UI</td>
                        <td>29 Sep, 2019</td>
                        <td>04 Oct, 2019</td>
                        <td>$122</td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>Blog Template</td>
                        <td>05 Oct, 2019</td>
                        <td>16 Oct, 2019</td>
                        <td>$164</td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>Redesign - Multipurpose Landing</td>
                        <td>17 Oct, 2019</td>
                        <td>05 Nov, 2019</td>
                        <td>$192</td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>Logo Branding</td>
                        <td>04 Nov, 2019</td>
                        <td>05 Nov, 2019</td>
                        <td>$94</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> -->




    <b-modal v-model="modalOk" id="modal-xl" size="xl" :title="$t('pages.userProfile.formulaire.title')"
      title-class="font-18" hide-footer>

      <div class="card-body">
        <form>

          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label">{{ $t('pages.userProfile.formulaire.name.label')
                }}</label>
                <div class="col-lg-12">
                  <input id="projectname" name="projectname" type="text" class="form-control"
                    :placeholder="$t('pages.userProfile.formulaire.name.placeholder')" v-model="name" />
                </div>
              </div>
            </div>


            <div class="col-xl-6">
              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label">{{ $t('pages.userProfile.formulaire.surname.label')
                }}</label>
                <div class="col-lg-12">
                  <input id="projectname" name="projectname" type="text" class="form-control"
                    :placeholder="$t('pages.userProfile.formulaire.surname.placeholder')" v-model="surname" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6">
              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label">{{ $t('pages.userProfile.formulaire.username.label')
                }}</label>
                <div class="col-lg-12">
                  <input id="projectname" name="projectname" type="text" class="form-control"
                    :placeholder="$t('pages.userProfile.formulaire.username.placeholder')" v-model="username" />
                </div>
              </div>
            </div>

            <div class="col-xl-6">
              <div class="form-group row mb-4">
                <label for="projectname" class="col-form-label">{{ $t('pages.userProfile.formulaire.tel.label') }}</label>
                <div class="col-lg-12">
                  <input id="projectname" name="projectname" type="text" class="form-control"
                    :placeholder="$t('pages.userProfile.formulaire.tel.placeholder')" v-model="telephone" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectname" class="col-form-label">{{ $t('pages.userProfile.formulaire.email.label') }}</label>
            <div class="col-lg-12">
              <input id="projectname" name="projectname" type="text" class="form-control" v-model="email"
                placeholder="$t('pages.userProfile.formulaire.email.placeholder')" />
            </div>
          </div>


        </form>
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px;">
            <button type="submit" class="btn btn-primary" style="position: relative; left: 30%;" @click="setInfos">
              {{ $t('updateButton') }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>





    <b-modal v-model="imageUpdate" id="modal-ml" size="ml" :title="$t('pages.image.formulaire.title')"
      title-class="font-18" hide-footer>

      <div class="card-body">
        <form>

          <div class="form-group row mb-4" style="display: none;">
            <label for="projectbudget" class="col-form-label">Image</label>
            <div class="col-lg-12">
              <input id="projectbudget" name="projectbudget" type="file" @change="onFileSeelcted($event)"
                class="form-control" ref="profileImg" accept=".png, .jpeg, .jpg" />
            </div>
          </div>
          <div style="width: 100%; display: flex; align-items: center; justify-content: center;">
            <img @click="updateImage()" :src="imageSrc" alt="Image" v-if="imageSrc != ''"
              style="height: 100px;width: 100px; border-radius: 50%;">
          </div>
          <br>
        </form>
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px;">
            <button type="submit" class="btn btn-primary" style="position: relative; left: 30%;" @click="setImage">
              {{ $t('addButton') }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <loaderProcess v-if="process == true"></loaderProcess>
    <!-- end row -->
  </Layout>
</template>